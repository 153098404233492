import { FC } from 'react';
import Box from '@mui/material/Box';

import { LogoDarkMode, LogoLightMode } from './Logo';

interface HeaderProps {
  mode: string | undefined;
}
const Header: FC<HeaderProps> = ({ mode }) => {
  return <Box>{mode === 'dark' ? <LogoDarkMode /> : <LogoLightMode />}</Box>;
};

export default Header;
