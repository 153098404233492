import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Typography, useTheme } from '@mui/material';

import Header from './Header';

const messageText = {
  expired: 'Link is expired',
  inactive: 'user is inactive',
  notFound: 'page not found',
};

const Error = () => {
  const theme = useTheme();

  const location = useLocation();

  const message = useMemo(() => {
    if (location.state.errorMessage === 'expired') {
      return messageText.expired;
    } else if (location.state.errorMessage === 'Wrong Request') {
      return messageText.inactive;
    }
    return messageText.notFound;
  }, [location.state.errorMessage]);

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#F5F5F5',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: '32px 240px',
        }}
        className="errorContainer"
      >
        <Header mode={theme.palette.mode} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '60px',
              alignItems: 'center',
            }}
          >
            <Box>
              {message === messageText.expired && <img src="/expired.png" alt="expired" />}
              {message === messageText.inactive && <img src="/inactive.png" alt="inactive" />}
              {message === messageText.notFound && <img src="/not-found.png" alt="notFound" />}
            </Box>
            <Typography variant="h3">Sorry, {message} </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" className="qa-lynon">
            @Lynon 2025
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Error;
