export const LogoLightMode = () => {
  return (
    <svg
      width="124"
      height="30"
      viewBox="0 0 124 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Logo">
        <path
          id="Vector"
          d="M9.30289 21.9013H21.6346V29.2341H0V0.550049H9.30289V21.9013Z"
          fill="#F54F0A"
        />
        <path
          id="Vector_2"
          d="M32.4519 0.550049L27.9086 12.6275H27.6923H27.4759L22.9327 0.550049H14.2788L23.149 21.9013V29.2341H27.6923H32.2356V21.9013L41.3221 0.550049H32.4519Z"
          fill="#F54F0A"
        />
        <path
          id="Vector_3"
          d="M59.0625 0.550049V18.882L58.6298 17.8036L51.7067 1.6284L51.0576 0.550049H42.8365L39.5913 7.8828V29.2341H48.0288V13.7059V12.6275L55.6009 29.2341H67.5V0.550049H59.0625Z"
          fill="#F54F0A"
        />
        <path
          id="Vector_4"
          d="M115.312 0.550049V18.882L114.88 17.8036L107.957 2.05973L107.308 0.550049H98.8701H95.8413V29.2341H104.279V12.6275L111.851 29.2341H123.75V0.550049H115.312Z"
          fill="#F54F0A"
        />
        <path
          id="Vector_5"
          d="M94.3271 5.29487C92.38 2.70684 89.5675 1.41282 88.2694 0.981486C86.755 0.334477 82.6444 -0.743867 78.9665 0.765819C77.4521 1.41283 75.9377 2.27551 74.6396 3.78519C73.3415 5.07921 71.8271 7.66725 70.7454 10.2553C69.6636 12.8433 69.231 15.8627 69.231 17.8037C69.231 19.7447 69.6636 21.4701 70.529 22.9798C72.2598 26.6461 76.154 28.5872 77.6685 29.0185C79.1829 29.6655 83.2935 30.7439 86.9713 29.2342C88.4858 28.5872 90.0002 27.7245 91.2983 26.2148C92.38 25.1365 93.4617 23.6268 94.3271 21.6857V5.29487ZM87.1877 16.5097C87.1877 16.5097 86.106 19.9604 83.7261 21.2544C82.4281 21.9014 81.13 21.4701 80.6973 21.2544C80.2646 21.0387 78.9665 20.6074 78.5339 19.0977C77.6685 16.7254 79.1829 13.2746 79.1829 13.2746C79.1829 13.2746 80.2646 9.82393 82.6444 8.52992C83.9425 7.88291 85.2406 8.31425 85.6733 8.52992C86.106 8.74559 87.404 9.17692 87.8367 10.6866C88.7021 13.059 87.1877 16.5097 87.1877 16.5097Z"
          fill="#F54F0A"
        />
      </g>
    </svg>
  );
};

export const LogoDarkMode = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="99" height="24" viewBox="0 0 99 24" fill="none">
      <path d="M7.44232 17.5212H17.3077V23.3874H0V0.440186H7.44232V17.5212Z" fill="white" />
      <path
        d="M25.9623 0.439941L22.3277 10.1019H22.1546H21.9815L18.3469 0.439941H11.4238L18.52 17.521V23.3872H22.1546H25.7892V17.521L33.0585 0.439941H25.9623Z"
        fill="white"
      />
      <path
        d="M47.2508 0.439941V15.1055L46.9046 14.2428L41.3661 1.30262L40.8469 0.439941H34.27L31.6738 6.30614V23.3872H38.4238V10.9646V10.1019L44.4815 23.3872H54.0007V0.439941H47.2508Z"
        fill="white"
      />
      <path
        d="M92.2508 0.439941V15.1055L91.9046 14.2428L86.3661 1.64769L85.8469 0.439941H79.0969H76.6738V23.3872H83.4238V10.1019L89.4815 23.3872H99.0007V0.439941H92.2508Z"
        fill="white"
      />
      <path
        d="M75.4617 4.2359C73.904 2.16547 71.654 1.13026 70.6155 0.785188C69.404 0.267582 66.1155 -0.595093 63.1732 0.612656C61.9617 1.13026 60.7502 1.82041 59.7117 3.02816C58.6732 4.06337 57.4617 6.1338 56.5963 8.20422C55.7309 10.2746 55.3848 12.6901 55.3848 14.243C55.3848 15.7958 55.7309 17.1761 56.4232 18.3838C57.8078 21.3169 60.9232 22.8697 62.1348 23.2148C63.3463 23.7324 66.6348 24.5951 69.5771 23.3873C70.7886 22.8697 72.0002 22.1796 73.0386 20.9718C73.904 20.1092 74.7694 18.9014 75.4617 17.3486V4.2359ZM69.7502 13.2077C69.7502 13.2077 68.8848 15.9683 66.9809 17.0035C65.9425 17.5211 64.904 17.1761 64.5579 17.0035C64.2117 16.831 63.1732 16.4859 62.8271 15.2782C62.1348 13.3803 63.3463 10.6197 63.3463 10.6197C63.3463 10.6197 64.2117 7.85915 66.1155 6.82393C67.154 6.30633 68.1925 6.6514 68.5386 6.82393C68.8848 6.99647 69.9232 7.34154 70.2694 8.54929C70.9617 10.4472 69.7502 13.2077 69.7502 13.2077Z"
        fill="white"
      />
    </svg>
  );
};
