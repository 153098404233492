import { useEffect, useMemo, useState, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from '@softland/forms';
import { yupResolver } from '@softland/forms/resolver';
import { AxiosError } from 'axios';
import {
  Box,
  Typography,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  List,
  ListItem,
  useTheme,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LoadingButton from '@mui/lab/LoadingButton';
import DoneIcon from '@mui/icons-material/Done';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';

import { resetValidation } from './validation';
import { resetApi } from 'modules/auth/api/auth.api';
import { useSendPassword } from 'modules/auth/api/hooks/useSendPassword';

interface PasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const Reset = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [success, setSuccess] = useState(false);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PasswordForm>({
    mode: 'onBlur',
    resolver: yupResolver(resetValidation),
  });
  const location = useLocation();
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const { mutate: sendPassword } = useSendPassword();

  useEffect(() => {
    const hasCode = queryParams.has('code');
    const getResponse = async () => {
      const code = queryParams.get('code');
      const response = await resetApi
        .getResetPasswordCode(code!)
        .then(res => res.data)
        .catch(err => err.message);
      if (response !== 'ok') {
        navigate('/error', {
          state: {
            errorMessage: response,
          },
        });
      } else if (response === 'ok') {
        setLoading(false);
      }
    };
    if (hasCode) {
      getResponse();
    }
  }, [queryParams, navigate]);

  const onSubmit: SubmitHandler<PasswordForm> = data => {
    const code = queryParams.get('code');

    setReset(true);
    sendPassword(
      { password: data.newPassword, code: code! },
      {
        onSuccess: response => {
          setReset(false);
          setSuccess(true);
          if (response.data === 'expired') {
            navigate('/error', {
              state: {
                errorMessage: response.data,
              },
            });
          }
        },
        onError: err => {
          setReset(false);
          navigate('/error', {
            state: {
              errorMessage: (err as AxiosError).message,
            },
          });
        },
      }
    );
  };

  const handleClickShowNewPassword = () => setShowNewPassword(show => !show);

  const handleMouseDownNewPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);

  const handleMouseDownConfirmPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#F5F5F5',
      }}
    >
      <CircularProgress />
    </Box>
  ) : success ? (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '16px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="h4" fontWeight={600} className="qa-password-created-title">
          <DoneIcon sx={{ color: theme.palette.success.main, width: '26px', height: '26px' }} />
          Password Created!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: 'rgba(0, 0, 0, 0.6)',
            marginTop: '7px',
          }}
          className="qa-password-created-message"
        >
          Your password has been successfully created.
        </Typography>
      </Box>
      <LoadingButton
        id="qa-navigate-to-login-page"
        sx={{ width: '100%', height: '42px', marginTop: '20px' }}
        size="medium"
        variant="contained"
        onClick={() => {
          navigate('/login');
          setSuccess(false);
        }}
      >
        login
      </LoadingButton>
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'none',
        borderRadius: '16px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography variant="h4" className="qa-password-created-title" fontWeight={600}>
            Create Password
          </Typography>
        </Box>
        <Box>
          <FormControl
            sx={{ width: '100%', margin: '14px 0px' }}
            variant="outlined"
            size="medium"
            error={!!errors.newPassword}
          >
            <InputLabel htmlFor="qa-new-password">New Password</InputLabel>
            <OutlinedInput
              {...register('newPassword')}
              id="qa-new-password"
              tabIndex={1}
              onChange={event => setPasswordValue(event.target.value)}
              type={showNewPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  {isValid && (
                    <DoneIcon
                      sx={{ color: theme.palette.success.main, width: '20px', height: '20px' }}
                      className="qa-done-icon"
                    />
                  )}
                  <IconButton
                    aria-label="toggle password visibility"
                    className="qa-login-password-visibility-btn"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                    edge="end"
                    tabIndex={3}
                  >
                    {showNewPassword ? (
                      <VisibilityOff className="qa-visibility-off" />
                    ) : (
                      <Visibility className="qa-visibility-on" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
            />
          </FormControl>
          <FormControl
            sx={{ width: '100%' }}
            variant="outlined"
            size="medium"
            error={!!errors.confirmPassword && !isValid}
          >
            <InputLabel htmlFor="qa-confirm-password">Confirm Password</InputLabel>
            <OutlinedInput
              {...register('confirmPassword')}
              id="qa-confirm-password"
              tabIndex={2}
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  {isValid && (
                    <DoneIcon
                      sx={{ color: theme.palette.success.main, width: '20px', height: '20px' }}
                      className="qa-done-icon"
                    />
                  )}
                  <IconButton
                    aria-label="toggle password visibility"
                    className="qa-login-password-visibility-btn"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                    tabIndex={4}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOff className="qa-visibility-off" />
                    ) : (
                      <Visibility className="qa-visibility-on" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>
          {!isValid && (
            <Typography
              variant="caption"
              sx={{ color: theme.palette.error.main, padding: '10px' }}
              className="qa-error"
            >
              {errors.confirmPassword?.message}
            </Typography>
          )}
          <Box
            sx={{
              marginTop: '14px',
            }}
          >
            <Typography
              variant="caption"
              fontWeight="600"
              sx={{ color: theme.palette.text.secondary }}
              className="qa-password-contain"
            >
              Password must contain
            </Typography>
            <List dense={true}>
              <ListItem sx={{ padding: '0px 16px' }} className="qa-min-char-list">
                <ListItemIcon sx={{ minWidth: '25px' }}>
                  {passwordValue.length < 8 ? (
                    <FiberManualRecordIcon
                      sx={{ color: theme.palette.error.main, width: '9px', height: '9px' }}
                      className="qa-not-done-icon"
                    />
                  ) : (
                    <DoneIcon
                      sx={{ color: theme.palette.success.main, width: '20px', height: '20px' }}
                      className="qa-done-icon"
                    />
                  )}
                </ListItemIcon>
                <ListItemText sx={{ fontSize: '12px' }} className="qa-min-char-text">
                  <Typography variant="caption">A Minimum of</Typography>{' '}
                  <Typography
                    variant="caption"
                    fontWeight="600"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    8 character
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ padding: '0px 16px' }} className="qa-uppercase-list">
                <ListItemIcon sx={{ minWidth: '25px' }}>
                  {/[A-Z]/.test(passwordValue) ? (
                    <DoneIcon
                      sx={{ color: theme.palette.success.main, width: '20px', height: '20px' }}
                      className="qa-done-icon"
                    />
                  ) : (
                    <FiberManualRecordIcon
                      sx={{ color: theme.palette.error.main, width: '9px', height: '9px' }}
                      className="qa-not-done-icon"
                    />
                  )}
                </ListItemIcon>
                <ListItemText sx={{ fontSize: '12px' }} className="qa-uppercase-text">
                  <Typography variant="caption"> At least one</Typography>{' '}
                  <Typography
                    variant="caption"
                    fontWeight="600"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    uppercase letter
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ padding: '0px 16px' }} className="qa-special-character-list">
                <ListItemIcon sx={{ minWidth: '25px' }}>
                  {/[`\\/!@#$%^&*()_+={};':"|,.<>?~]/.test(passwordValue) ? (
                    <DoneIcon
                      sx={{ color: theme.palette.success.main, width: '20px', height: '20px' }}
                      className="qa-done-icon"
                    />
                  ) : (
                    <FiberManualRecordIcon
                      sx={{ color: theme.palette.error.main, width: '9px', height: '9px' }}
                      className="qa-not-done-icon"
                    />
                  )}
                </ListItemIcon>
                <ListItemText sx={{ fontSize: '12px' }} className="qa-special-character-list">
                  <Typography variant="caption"> At least one</Typography>{' '}
                  <Typography
                    variant="caption"
                    fontWeight="600"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    special character
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
        <LoadingButton
          id="qa-reset-password"
          loading={reset}
          sx={{ width: '100%', height: '42px' }}
          size="medium"
          variant="contained"
          type="submit"
        >
          reset
        </LoadingButton>
      </form>
    </Box>
  );
};

export default Reset;
