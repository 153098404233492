import { Typography, Box } from '@mui/material';

const Footer = () => {
  return (
    <Box>
      <Typography variant="caption" className="qa-lynon">
        @Lynon 2025
      </Typography>
    </Box>
  );
};

export default Footer;
