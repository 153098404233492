import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';

import Login from './login/component/Login';
import Reset from './login/component/Reset';
import Footer from './login/component/Footer';
import Header from './login/component/Header';
import styled from '@emotion/styled';

const StyledImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
});

const Auth = () => {
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:1024px)');

  console.log(matches);

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        height: '100vh',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#F5F5F5',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: '32px',

          maxWidth: '708px',
          ...(matches ? { marginLeft: 'auto', width: '50%' } : { margin: 'auto' }),
        }}
      >
        <Header mode={theme.palette.mode} />
        <Box
          sx={{
            zIndex: '1',
            maxWidth: '400px',
            height: 'auto',
            bgcolor:
              theme.palette.mode === 'dark'
                ? theme.palette.background.paper
                : theme.palette.background.paper,
            borderRadius: '16px',
          }}
        >
          <Routes>
            <Route path="/" index element={<Navigate to="/login" />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/reset" element={<Reset />}></Route>
          </Routes>
        </Box>
        <Footer />
      </Box>
      {matches && (
        <Stack justifyContent="center" padding="32px">
          <StyledImage src="/background.png" alt="img" />
        </Stack>
      )}
    </Stack>
  );
};

export default Auth;
